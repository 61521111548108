.item-container{
display: flex;
justify-content: space-between;
align-items: center;
font-size: 1rem;
font-weight: 500;

}

.item-quantity-name{
    display: flex;
    gap: 0.2rem;
}
