.loc-di{
display: flex;
align-items: center;
justify-content: space-between;
}

.location-title{
margin: 0px;
font-size: 1.3rem;
font-weight: 500;
}
.get-direction{
color:#1DB4B8;
}

.order-status, .pickup-time, .delivery-time{
display: flex;
flex-direction: column;
align-items: center;
font-size: 13px
}

.order-info{
display: flex;
justify-content: center;
gap: 1rem;
padding-top: 3%;
grid-row-start: 2;
height: fit-content;
}

.customer-location-title{
font-size: 14px;
height: fit-content;
}

.location-map{
display: flex;
flex-direction: column;
grid-template-rows: 1fr 1fr ;
justify-content: center;
height: fit-content;
gap:1rem;
}

.small-map{
width: 20rem;
height: 6rem;
border-radius: 18px;
}

.small-map-div:hover{
cursor:pointer;
}

.get-direction:hover{
cursor:pointer;
}
