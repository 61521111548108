.delivery-completed-dialog{
.MuiDialog-paper{
    background-color:#1DB4B8 !important;
}
}
.delivery-completed-title{
display: flex;
flex-direction: column;
align-items: center;
color:#FFFFFF;
font-weight: bold;
font-size: 1.4rem;
justify-content: center;
}

.text-for-driver{
display: flex;
color:#FFFFFF;
font-weight: lighter;
font-size: 1rem;
justify-content: center;   
}

.amount-for-driver{
display: flex;
color:#FFFFFF;
font-weight: bold;
font-size: 1.3rem;
justify-content: center;  
}

.tips-for-driver{
display: flex;
color:#D9C22B;
font-weight: 500;
font-size: 1rem;
justify-content: center;     
align-items: center;
gap:0.5rem
}

.for-driver{
display: grid;
gap: 0.7rem;
}

.rating-buttons{
display: grid;
justify-content: center;
}

.delivery-completed-top{
display: grid;
gap: 2rem;
margin-top:10px;
grid-row-start: 1;
}
    
.delivery-completed-bottom{
display: grid;
gap: 2rem;

grid-row-start: 2;
}

.delivery-completed{
display: grid;
grid-template-rows: repeat(2 50%);
align-items: center;
justify-content: center;
gap:80px;
user-select: none;
}

.rate-customer-title{
display: flex;
color:#FFFFFF;
font-weight: bold;
font-size: 1.2rem;
justify-content: center;    
}
    
.rating-icons{
display: flex;
justify-content: center;
gap:0.7rem
}
    
.rating{
display: grid;
gap: 1.3rem;
}

