.address-icon, .time-icon{
width: 35px;
height: 35px;
background-color: #FFFFFF;
color:#1DB4B8;
display:flex;
align-items: center;
justify-content: center;
border-radius: 4px;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.delivery-address-container, .delivery-time-container{
display: flex;
gap: 1rem;
width: fit-content;
}

.client-address, .time-duration{
font-weight: bold;
font-size: 0.85rem;
}

.delivery-address-title, .time-title{
font-weight: 500;   
color: #838383;
font-size: 0.8rem;
}

.delivery-details{
display: grid;
gap: 2rem;
}

.client-name{
font-weight: bold;
color: #FFFFFF;
font-size: 1.1rem;
}

.client-title{
font-weight: 500;
color: #FFFFFF; 
font-size: 0.9rem;
}

.delivery-details{
display: grid;
background-color: #FFFFFF;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
padding: 20px 5px 20px 25px;
width: 18rem;
}

.client-info-container{
display: flex;
align-items: center;
gap: 1rem;
background-color: #1DB4B8;
padding: 5px 15px 5px 15px;
width: 18rem;
}

.client-text{
display: grid;
align-items: center;
}

.contact-client{
display: flex;
gap: 0.5rem;
transform: translate(25px, 0px);
width: 18rem;
}

.delivery-info{
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    user-select: none;
}


