.delivery-summary-container{
max-width: 18rem;
width: 100%;
position:fixed;
top:50%;
left:50%;
transform: translate(-50%, -50%);
background-color: #FFFFFF;
padding: 20px;
border-radius: 20px;
box-shadow: 0px 0px 18px 0px (0,0,0,0.75);
}

.summary-title{
font-size: 1rem;
font-weight: bold;
display: flex;
justify-content: center;
}

.delivery-order-detail{
display: flex;
flex-direction: row;
justify-content: center;
gap: 0.5rem;
font-size: 1rem;
}
    
.delivery-order-title{
font-weight: 500;
}

.delivery-order-fullnbr{
display: flex;
gap: 0.09rem;
font-weight: bold;
}
    
.delivery-order-tag{
color: #1DB4B8;
}

.delivery-summary-details{
display: grid;
gap: 0.5rem;
font-size: 0.75rem;
padding-top: 30px;
padding-bottom: 30px;
justify-content: center;
}

.summary-icon-text{
display: flex;
align-items: center;
gap: 0.5rem;
}

.summary-location{
color: #1DB4B8;
}

.summary-food-title{
font-size: 1.2rem;
font-weight: bold;
display: flex;
justify-content: center;
margin-top:10px
}
    

.delivery-payment-status-title{
display: flex;
font-weight: bold;
font-size: 1.2rem;
}
        
.delivery-payment-status{
display: flex;
align-items: center;
justify-content: center;
gap:40px;
margin-top:35px
}

.summary-payment-method{
display: flex;
font-size: 0.9rem;
font-weight: 500;
}

.summary-total-container{
display: flex;
align-items: center;
justify-content: center;
gap: 40px;
font-weight: bold;
font-size: 1rem;
margin-top: 10px;
margin-bottom: 20px;
}

.total-amount{
color: #1DB4B8;   
}

.delivered-button{
    display: flex;
    justify-content: center;
    padding: 20px 10px 10px 10px;
    background-color: #FFFFFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    }

    .delivered-button button:disabled {
        color: #FFFFFF;
      }
      
