
.home-container{
display: grid;
padding: 1rem;
grid-template-rows: repeat(2,0.5fr);
user-select: none;
}

.greeting-container{
display: grid;
justify-content: center; 
align-items: center;
grid-template-columns: repeat(11, 2rem);
grid-template-rows: repeat(2,2rem);
padding: 1rem;
}

.greeting-ready{
display: grid;
grid-template-columns: repeat(4, 4rem);
grid-template-rows: repeat(2, 1.5rem);;  
grid-row-start: 1;
grid-column-start: 1;
grid-column-end:7;
align-items: center;
}

.greeting{
grid-column-start: 1;
grid-column-end:3;
font-size: 1.2rem;
font-weight: 700;
}

.ready{
grid-column-start: 1;
grid-column-end:span 4;
color: #818181;
font-weight: 600;
font-size: 0.9rem;
}

.greet-buttons{
display: grid;
grid-template-columns: repeat(2, 2.5rem);
gap: 0.6rem;
grid-column-start:10 ; 
justify-content: center;
grid-row-start: 1;
align-content: center;
}

.startwork{
display: grid;
grid-row-start: 2;
justify-content: center;
}



