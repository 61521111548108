.order-details-head{
display: grid;
grid-template-columns: repeat(6,4em);
grid-template-rows: repeat(2,3em);
justify-content: center;
align-items: center;
user-select: none;
}

.split-line{
border-bottom: 2px dashed #818181; 
padding-bottom: 15px;
margin-bottom: 10px; 
}

.back-to-orders{
grid-column-start:1 ;

}

.details-order-detail{
display: grid;
grid-column-start:3;
grid-column-end:5 ;
justify-content: center;
}


.other-details-info{
display: grid;
grid-template-columns: repeat(5, 3rem);
grid-template-rows: repeat(3, 1rem);
grid-column-start:3;
grid-column-end:5 ;
grid-row-start: 2;
justify-content: center;
font-size: small;
gap: 0.2rem;
}

.order-date{
display: grid;
grid-column-start:2;
grid-column-end:5 ;
grid-row-start: 1;
justify-content: center;
}

.order-time{
display: grid;
grid-column-start:2;
grid-column-end:5 ;
grid-row-start: 2;
justify-content: center;
}

.order-branch{
display: grid;
grid-column-start:1;
grid-column-end:6 ;
grid-row-start: 3;
justify-content: center;
}

.details-order-detail{
display: flex;
flex-direction: row;
justify-content: center;
gap: 0.5rem;
font-size: 1.7rem;
}

.order-fullnbr{
display: flex;
gap: 0.09rem;
font-weight: bold;
}

.order-tag{
color: #1DB4B8;
}

.order-date, .order-time, .order-branch{
display: flex;
justify-content: center;
gap: 0.5rem;
}

.details-order-title{
font-weight: 500;
}

.Location-container{
max-height:150px;
max-width: 400px;
}

.address-box{
max-height:220px;
max-width: 400px;
padding-left: 50px; 
}

.client-order-details-container{
display: grid;
width: 20rem;

}

.details-container{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
user-select: none;
}


.customer-payment-status-title{
display: flex;
font-weight: 500;
font-size: 1.3rem;
}

.customer-payment-status{
display: flex;
align-items: center;
justify-content: space-between;
}
