.history{
display: flex;
flex-direction: column;
align-items: center;
gap: 1rem;
margin-bottom: 1%;
padding-top: 90px;
user-select: none;
}
    
.history-subtotal {
display: flex;
justify-content: space-between;
}

.history-delivery-charge{
display: flex;
justify-content: space-between;
}

.history-tip{
display: flex;
justify-content: space-between;
}

.history-total{
display: flex;
justify-content: space-between;
}
 
.history-total-container{
display: flex;
flex-direction: column;
padding: 1rem;
gap: 0.3rem;
}

.history-subtotal, .history-delivery-charge, .history-tip{
font-weight: 500;
}

.history-total{
font-weight: bolder;
}

.history-box{
display: flex;
flex-direction: column;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 35px;
width: 21rem;
font-family: 'Segoe UI';
font-size: 0.95rem;
}

.history-closing-report{
display: flex;
justify-content: center;
}

.date-history{
display: grid;
grid-auto-flow: column;
margin-right:180px;
font-size: 13px;
align-items: center;
gap: 0.7rem;
margin-bottom: 5px;
user-select: none;
}

.history-buttons{
display:grid; 
grid-auto-flow: column;
justify-content: space-between;
align-items: center;
width: 20rem;
user-select: none;
}

.history-box{
background-color: #FFFFFF;
}

.cash-button:hover, .card-button:hover, .online-button:hover{
border-bottom: solid;
border-color: #1DB4B8;
border-width: 3px;
font-weight: bold;
cursor: pointer;
transition: 0.1s ease-in-out;
}

.history-fixed{
display: grid;
gap: 1rem;
position: fixed;
justify-content: center;
align-items: center;
background-color: #F9F9F9 ;
left: 0;
right: 0;
z-index: 1;
padding-top:10px;
padding-bottom: 10px;
transform: translate(0px, -5px);
}

