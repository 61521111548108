.items-subtotal, .items-delivery-charge, .items-discount, .items-total{
    display: flex;
    justify-content: space-between;
    font-size:1rem
    }
 
    .items-total-container{
        display: grid;
        width: 20rem;
        margin-top: 2rem;
        gap: 0.7rem;
    }

    .items-total{
        font-weight: 600;
    }

    .items-title{
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 1.3rem;
        font-weight: 500;
        
    }

    .data-items{
        display: grid;
        width: 20rem;
        gap: 1.5rem;
        
    }