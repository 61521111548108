.order-box{
position: relative;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
border-radius: 20px;
padding: 0.5rem;
width: 21rem ;
font-family: 'Segoe UI';
background-color: #FFFFFF;
}

.order-container{
 display:grid;
 grid-template-columns: repeat(21, 1rem);
 grid-template-rows: repeat(3, 1fr);
 font-size: small;
 align-items: center;
}

.order-title{
display: grid;
grid-column-start: 3;
grid-column-end: 6;
justify-content: center;

}

.order-details{
    display: grid;
    grid-column-start: 19;
    grid-column-end: 21;
    justify-content: center;
    column-gap: 50px;
    color: #1DB4B8;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
}

.order-tag{
    color: #1DB4B8 ;
    font-weight: bold;
}

.distance{
display: grid;
grid-row-start: 2;
grid-column: span 2;
justify-items: center;
font-size:0.7rem
}

.order-location-logo{
display: grid;
justify-items: center;
grid-column: span 2;
}
.location{
   display: grid;
   grid-column-start: 3;
   grid-column-end: 16;
   color: #818181;
   font-size: 0.7rem;
   gap: 0.7rem;

}

.order-status-title {
    display: grid;
    grid-column-start: 2;
    grid-column-end: 7;
    justify-content: center;
    
}

.pickup-time-title{
    display: grid;
    grid-column-start: 7;
    grid-column-end: 15;
    justify-content: center;
}


.delivery-time-title{
    display: grid;
    grid-column-start: 15;
    grid-column-end: 21;
    justify-content: center;
    

}

.order-tag-nbr{
display: flex;
gap: 0.05rem;
font-weight: bold;
}

.pickup-time, .delivery-time{
    color: #1DB4B8;
}


.order-status-title, .pickup-time-title, .delivery-time-title{
    font-size: 0.75rem; 
}

.order-details-button{
border-style: none;
background-color: white;
border-radius: 20px;
opacity: 100;
padding: 0px;
}

.order-box:hover{
    background-color: rgb(245, 245, 245);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    }

    
    
        


