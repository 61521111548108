.adress-box{
display: flex;
flex-direction: column;
grid-template-columns: repeat(9, 2rem);
grid-template-rows: repeat(9, 2rem);
justify-content: center;
row-gap: 0.5rem;
}

.adress-title{
margin: 0px;
font-size: 1.3rem;
font-weight: 500;
}


.adress-location-distance{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.subtext{
    color: #818181;
    font-size: small;
}

.adress-street, .adress-building, .adress-floor, .adress-apartment{
    display: flex;
    gap: 10px;
    align-items: center;
}

