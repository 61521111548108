.orders-container{
display: grid; 
justify-content: center;
gap: 1rem;
padding-top: 0;
margin-bottom: 150px;

}

.start-all-button {
position: fixed;
bottom: 85px;
left: 50%;
transform: translateX(-50%);
z-index: 1;
}

.orders-fixed{
position: fixed;
padding-top: 2%;
padding-bottom: 30px;
padding-right: 140px;
width: 22rem;
z-index: 1;
background-color: #F9F9F9;
transform: translate(-10px, -4px)
}

.orders{
margin-top: 71px;
}

.switch-button{
transform: translate(20px, 10px)
}
