.customer-title{
margin-bottom:3%;
margin-top:2%;
font-size: 1.3rem;
font-weight: 500;
}

.customer-avatar{
    display:flex;
    align-items:center;
    gap:0.7rem;
}

.customer-avatar{
    display:flex;
    align-items:center;
    gap:0.7rem;
}

.customer-name{
    color:#1DB4B8;
    font-Weight:bold;
    font-Size:1.2rem;
    width: fit-content;
}

.customer-call-message{
display: flex;
gap: 0.7rem;
}

.customer-details{
    display: flex;
    justify-content: space-between;
}