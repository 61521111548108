.cr-head{
    display: grid;
    grid-template-columns: repeat(6,4em);
    grid-template-rows: repeat(2,3em);
    justify-content: center;
    align-items: center;
    }

.cr-fixed{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #F9F9F9;
  z-index: 1;
}

.back-to-history{
        grid-column-start:1 ;
        }

.cr-title{
    display: grid;
    grid-column-start:2;
    grid-column-end:6 ;
    justify-content: center;
    font-weight: 500;
    font-size: 1.3rem;
           }


.cr-date-time{
display: grid;
grid-template-columns: repeat(5, 3rem);
grid-template-rows: repeat(3, 1rem);
grid-column-start:3;
grid-column-end:5 ;
grid-row-start: 2;
justify-content: center;
font-size: small;
gap: 0.2rem;
            }

.cr-date{
display: flex;
grid-column-start:2;
grid-column-end:5 ;
grid-row-start: 1;
justify-content: center;
gap:0.5rem;
                }

.cr-time{
display: flex;
grid-column-start:2;
grid-column-end:5 ;
grid-row-start: 2;
justify-content: center;
                    }

.cr-dt-title{
font-weight: 500;
        }
                        


.closing-report-orders{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
   margin-top: 380px;
margin-bottom: 100px
    }

.closing-report{
        display: flex;
        flex-direction: column;
        background-color: #F9F9F9;
        }

.cr-date-container, .cr-time{
        display: flex;
        justify-content: center;
        gap: 0.5rem;
}


.cr-time{
margin-bottom: 1%;    
}

  

.cr-time-title, .cr-date-title{
     font-weight: 500;
}


.summary-payment-title, .summary-payment-number, .summary-payment-cash, .summary-payment-card, .summary-payment-online, .summary-payment-total, .summary-payment-delivery, .summary-payment-tip{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summary-payment-total-titles{
display: flex;
flex-direction: column;
}

.summary-payment-title{
font-size: medium;
font-weight: 500;
}



.summary-payment-box{
display: flex;
justify-content: center;
}

.summary-payment-container{
    display: grid;
    gap: 0.5rem;
    width:330px;
    border-style: solid;
    border-color: grey;
    border-width: 0.1rem;
    border-radius: 5%;
    padding: 20px;
    margin: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: smaller;
    background-color: #FFFFFF;
}


.summary-payment-title{
    display: flex;
    justify-content: center;
}

.stop-work-button {
  position: fixed;
  bottom: 85px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
