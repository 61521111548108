.button-start{
    background-color: #1DB4B8 ;
    border: none; 
    color: white; 
    text-align: center; 
    text-decoration: none; 
    font-weight: 500;
    margin-right: 1px; 
    cursor: pointer; 
    border-radius: 20px;
    height:45px;
    width: 120px;
    display: grid;
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column-start: 4;
  }

 .start-work {
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  padding: 1.2rem;
 max-width: 21rem;
  justify-content: center;
  background-color: #FFFFFF;
  } 

  
  .sw-container{
display: grid;
grid-template-columns: repeat(5, 4rem);
grid-template-rows: repeat(3, 1.5rem);
  }

.date-time{
grid-column-start: 1;
grid-column-end: 4;
grid-row-start: 1;
grid-row-end: span 3;
display: grid;
align-items: center ;
  }

  .user-status-home{
display: grid;
    grid-column-start: 4;
    grid-column: span 2;
    justify-content: center;
  }

  .user-status{
    display: flex;
}

.date-container, .time-container{
    display: flex;
    gap:0.2rem;
    font-size: small;
    align-items: center;
}



.user-status-icon > svg{
  color: #333333;
  margin-right: 2px;
  }

.user-status-icon--off > svg{
    color: #CF0000; width: 18px;
    }

.user-status-icon--on > svg{
    color: #04A147; width: 18px;
        }

.user-status-text {
        display: inline-block;
        vertical-align: middle;
        color: #333333;
        font-size: 17px;
        font-weight: light;
        margin-left: 2px;
        }
          
.user-status-text--on {
        color: #04A147;
        }
          
.user-status-text--off {
        color: #CF0000;
          }



.work-timing{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;
margin-top: 20px;
font-weight: 500;
font-size: large;
}



