.mood-ratings{
display: flex;
gap:1rem
}

.mood-size{
width:55px;
height:55px;
}

.mood-size:hover{
transform: scale(1.2);
transition: 0.15s ease-in-out;
cursor: pointer;
/*border-bottom: solid;
padding-bottom: 5px;
border-width: 3px;
border-radius: 20px;*/
}

.very-dissatisfied{color:#BD3737}

.dissatisfied{color:#FF7E00}

.neutral{color:#E3C500}

.satisfied{color:#F7F759}

.very-satisfied{color:#8BE11B}

.mood-rating-container{
display: flex;
flex-direction: column;
align-items: center;
gap: 1rem;
}

.selected-mood{
    color: #FFFFFF;
    font-weight: 500;
    font-size:large ;
 
}