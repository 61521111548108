.greet-body{
display: block;
justify-content: center;

}


.name {
    color: #1DB4B8;
}
.greeting{
    color: #333333;
    margin-bottom: 0;
    padding-bottom: 0;
    width: max-content;

}



*{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

  