.single-history-box{
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 15px;
    width: 20rem;
    font-family: 'Segoe UI';
    font-size: 0.95rem;
    }

.single-history-box:hover{
    background-color: rgb(245, 245, 245);
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;    
}

    
    .history-title-container{
     display:flex;
     gap:0.7rem;
     align-items: center;
    }
    
    .history-title{
        display: flex;
        gap: 1rem;
        justify-content: space-evenly;
    }
    
    .history-details{
        display: flex;
        position: absolute;
        right: 0;
        margin: 5%;
        color: #1DB4B8;
        align-items: center;
    }
    
    .history-tag{
        color: #1DB4B8 ;
        font-weight: bold;
    }
    
    .history-location{
       display: flex;
       color: #818181;
       font-size: 0.7rem;
       gap: 0.7rem;
       
    }
    
    .history-delivery-time{
    font-size: 0.88rem;
    color: #1DB4B8;
    }
    
    .history-delivery-info{
        display: flex;
        gap: 0.5rem;
        padding-top: 3%;
        align-items: center;

    }
    
    .history-tag-nbr{
    display: flex;
    gap: 0.05rem;
    font-weight: bold;
    }
    
    
    .history-price{
font-weight: bolder;
color: #1DB4B8;

    }

    .history-delivery-price{
display: flex;
justify-content: space-between;
align-items: center;

    }