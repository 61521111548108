.direction-head{
position: fixed;
display: grid;
grid-template-columns: repeat(6,4em);
grid-template-rows: repeat(1,3.7em);
justify-content: center;
align-items: center;
background-color: #F9F9F9 ;
top: 0;
left: 0;
right: 0;
z-index: 1;
}

.map-direction{
margin-top: 60px;
width: 100%;
height:80vh;
border-top-right-radius: 30px;
border-top-left-radius: 30px;
}

.direction-title{
font-size: 1.55rem;
font-weight: 500;
grid-column-start:3;
grid-column-end:5;
display: flex;
justify-content: center;
user-select: none;
}

.back-to-details{
grid-column-start:1;
grid-column-end:2;    
display: flex;
justify-content: center;  
}
.direction-delivery-info{
display: flex;
justify-content: center;
position: fixed;
border-radius: 30px;
left: 0;
right:0;
bottom:0;
transform: translate(0px, -15px);
}

.delivery-summary-popup{
z-index: 2;
}
.direction-dialog{
.MuiDialog-paper{
background-color: #F9F9F9  !important;
  }
};
