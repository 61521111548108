.body-signup {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#1DB4B8;
    width:100%;
    height: 100vh;
  }
  
  .signup-form{
  display: flex;
  flex-direction: column;
  }
  
  .signup-title{
    font-size: 35px;
    font-weight: 500;
    color: #FFFFFF;
  }
  
  .signup-subtext{
    color: #FFFFFF;
  }
  
  .signup-head{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 70px;
  
  }
  
  .signup-inputs{
    margin-bottom: 60px;
  }
  
  .signup-button{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  }
  
  .go-login-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #FFFFFF;
    gap: 0.5rem;
    text-decoration: none;
    transition: opacity 0.3s ease;
  }

  .go-login-page:hover{
    opacity:0.7;

  }
  
  .MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
      border-bottom: none !important;
    }
  
  .MuiFilledInput-root{
  background-color: rgba(255, 255, 255, 0.4) !important;
  border-radius: 15px !important;
  }
  
  .MuiFilledInput-root input::placeholder {
      color: #FFFFFF ;
      opacity: 100%;
    }
  
    .MuiFilledInput-input {
      font-size: 16px; 
      font-family: Arial, sans-serif; 
      font-weight: bold; 
      color: #FFFFFF !important; 
      transform: translate(1px, -8px)
    }
  
  
  .email-icon, .password-icon{
  color: #FFFFFF;
  transform: translate(1px, -8px)
  }
  

  