.single-cr-container{
    position: relative;
    max-height:150px;
    max-width: 500px;
    width: 300px;
    font-family: 'Segoe UI';
    font-size: 0.95rem;
   
    }
    
    .single-cr-title-container{
     display:flex;
     gap:0.3rem;
     align-items: center;
    }
    
    .single-cr-title{
        display: flex;
        gap: 1rem;
        justify-content: space-evenly;
    }
    
    .single-cr-payment{
        display: flex;
        position: absolute;
        right: 0;
        color: #1DB4B8;
        align-items: center;
        text-decoration: none;
        font-size: smaller;
    }
    
    .single-cr-tag{
        color: #1DB4B8 ;
        font-weight: bold;
    }
    
 
    
    .single-cr-delivery-time{
    font-size: 0.88rem;
    color: #1DB4B8;
    }
    
    .single-cr-delivery-info{
        display: flex;
        gap: 0.5rem;
        padding-top: 3%;

    }
    
    .single-cr-tag-nbr{
    display: flex;
    gap: 0.05rem;
    font-weight: bold;
    }
    
    
    .single-cr-price{
font-weight: bolder;
color: #1DB4B8;

    }

    .single-cr-delivery-price{
display: flex;
justify-content: space-between;


    }